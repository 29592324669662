.next-project-section {
    padding: 70px 0 140px 0;
    background: #F4FAFE;
    min-height: 910px;

    .next-project-content {
        position: sticky;
        top: 100px;
        h2 {
            font-size: 40px;
            line-height: 150%;
            font-weight: 700;
            color: #040405;
            margin-bottom: 8px;
        }
        p {
            font-size: 16px;
            line-height: 150%;
            font-weight: 500;
            color: #686869;
            margin-bottom: 24px;
        }
        .next-project-list {
            margin: 0;
            padding: 0;
            padding: 16px 0 12px 0;
            li {
                padding-bottom: 20px;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                font-size: 16px;
                line-height: 22px;
                font-weight: 500;
                color: #202020;
                img {
                    margin-right: 16px;
                }
            }
        }
    }
    .next-project-card {
        border-radius: 16px;
        border: 2px solid rgba(0, 134, 230, 0.20);
        background: linear-gradient(132deg, #FFF 0%, rgba(255, 255, 255, 0.90) 100%);
        padding: 20px;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        margin-bottom: 20px;
        .next-project-left-content {
            position: relative;
            margin-right: 22px;

            .next-project-profile {
                width: 72px;
                height: 72px;
                border-radius: 12px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 12px;
                border: 2px solid #D6EEFF;
                background: #fff;
                position: relative;
                z-index: 9;

                img {
                    width: 40px;
                    height: 40px;
                }
            }
            span {
                width: 72px;
                height: 72px;
                border-radius: 12px;
                border: 1px solid #fff;
                opacity: 0.1;
                background: linear-gradient(283deg, #0086E6 5.56%, #8DD0FF 113.66%);
                position: absolute;
                top: 0;
                left: 0;
                opacity: 0;
                transition: 0.3s;
            }
        }
        &:hover {
            box-shadow: 0px 30px 50px 0px rgba(0, 0, 0, 0.04);
            .next-project-left-content {
                span {
                    opacity: 0.1;
                    top: 10px;
                    left: 10px;
                }
            }
        }

        .next-project-right-content {
            h3 {
                font-size: 20px;
                line-height: 140%;
                font-weight: 600;
                color: #040405;
                margin-bottom: 7px;
            }
            p {
                font-size: 16px;
                line-height: 150%;
                font-weight: 400;
                color: #040405;
                margin: 0;
            }
        }
    }
    .next-project-green-card {
        .next-project-left-content {
            .next-project-profile {
                border: 2px solid rgba(138, 188, 0, 0.07);
            }
            span {
                background: linear-gradient(283deg, #8ABC00 5.56%, #F1F8DA 113.66%);
            }
        }
    }
}

// technology css
.next-technology-section {
    padding-top: 140px;
    .next-project-content {
        h2 {
            max-width: 420px;
        }
    }
}
@media only screen and (max-width: 1400px) and (min-width: 1025px) {
    .container {
        max-width: 1000px !important;
    }
    .next-project-section {
        min-height: 760px;
    }
    .next-project-section .next-project-content h2 {
        font-size: 32px;
        margin-bottom: 4px;
    }
    .next-project-section .next-project-card {
        padding: 18px;
        border-radius: 12px;
    }
    .next-project-section .next-project-card .next-project-right-content h3 {
        font-size: 16px;
        margin-bottom: 4px;
    }
    .next-project-section .next-project-card .next-project-right-content p {
        font-size: 14px;
    }
    .next-project-section .next-project-content .next-project-list li {
        padding-bottom: 16px;
        font-size: 14px;
    }
    .next-project-section .next-project-card .next-project-left-content .next-project-profile {
        width: 48px;
        height: 48px;
        border-radius: 8px;
    }
    .next-project-section .next-project-card .next-project-left-content .next-project-profile img {
        width: 24px;
        height: 24px;
    }
    .next-project-section .next-project-card .next-project-left-content span {
        width: 48px;
        height: 48px;
        border-radius: 8px;
    }
}

@media (max-width: 1024px) {
    .next-project-section {
        padding: 50px 0 80px 0;
        min-height: auto;
    }
    .next-project-section .next-project-content h2 {
        font-size: 32px;
        margin-bottom: 4px;
    }
    .next-project-section .next-project-card {
        padding: 18px;
        border-radius: 12px;
    }
    .next-project-section .next-project-card .next-project-right-content h3 {
        font-size: 16px;
        margin-bottom: 4px;
    }
    .next-project-section .next-project-card .next-project-right-content p {
        font-size: 14px;
    }
    .next-project-section .next-project-content .next-project-list li {
        padding-bottom: 16px;
        font-size: 14px;
    }
    .next-technology-section {
        padding-top: 80px;
    }
    // .next-project-section .next-project-card .next-project-left-content .next-project-profile {
    //     width: 48px;
    //     height: 48px;
    //     border-radius: 8px;
    // }
    // .next-project-section .next-project-card .next-project-left-content .next-project-profile img{
    //     width: 24px;
    //     height: 24px;
    // }
    // .next-project-section .next-project-card .next-project-left-content span{
    //     width: 48px;
    //     height: 48px;
    //     border-radius: 8px;
    // }
}

@media (max-width: 992px) {
    .next-project-section {
        padding: 34px 0 60px 0;
        .next-project-content {
            h2 {
                font-size: 24px;
            }
            p {
                font-size: 14px;
                margin-bottom: 14px;
            }
        }
        .next-project-card {
            padding: 12px 16px;
            .next-project-right-content {
                h3 {
                    font-size: 18px;
                    margin-bottom: 2px;
                }
                p {
                    font-size: 14px;
                }
            }
            .next-project-left-content {
                .next-project-profile {
                    width: 55px;
                    height: 55px;
                }
                span {
                    width: 55px;
                    height: 55px;
                }
            }
        }
    }
}

@media (max-width: 767px) {
    .next-project-section {
        .next-project-content {
            margin-bottom: 20px;
        }
        .next-project-card {
            padding: 16px;
            align-items: flex-start;
            margin-bottom: 12px;
            border-radius: 12px;
            .next-project-left-content {
                margin-right: 18px;
                .next-project-profile {
                    img {
                        width: 28px;
                        height: 28px;
                    }
                    width: 48px;
                    height: 48px;
                    border-radius: 9px;
                }
                span {
                    width: 48px;
                    height: 48px;
                    border-radius: 9px;
                    top: 7px;
                    left: 7px;
                }
            }
            .next-project-right-content {
                h3 {
                    font-size: 16px;
                }
            }
        }
    }
    .next-project-section .next-project-content {
        text-align: center;
    }
    .next-project-section .next-project-content h2 {
        text-align: center;
    }
    .next-project-section .next-project-content p {
        text-align: center;
    }
    .next-technology-section {
        padding-top: 60px;
    }
    .next-technology-section.next-project-section .next-project-content h2 {
        text-align: left;
    }
    .next-technology-section .next-project-content .next-project-list {
        padding: 10px 0;
    }
    .next-technology-section .next-project-content .next-project-list li img {
        width: 20px;
        margin-right: 12px;
    }
    .next-technology-section.next-project-section .next-project-content {
        text-align: left;
    }
}
